.header {
    padding: 20px;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 50px;;
    }

    &__site-name {
        font-size: 20px;
        margin: 0 0 0 10px;

        a {
            color: #fff;
            text-decoration: none;
        }

    }

    &__right {

        p {
            margin: 0;

            a {
                color: #fff;
                font-size: 14px;
                font-family: 'Inter', sans-serif;
                text-decoration: none;
                transition: .5s;
                -o-transition: .5s;
                -ms-transition: .5s;
                -moz-transition: .5s;
                -webkit-transition: .5s;

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}