.meme-section {
    padding: 50px 0;

    .form {
        width: 100%;

        &__group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 25px;

            &:last-child {
                margin: 0;;
            }
        }

        input[type="text"] {
            width: 44%;
            border: solid 1px #D5D4D8;
            border-radius: 5px;
            padding: 10px;
            color: #000;
            font-family: 'Karla', sans-serif;
            font-size: 16px;
            font-weight: 300;

            &:focus {
                outline: solid 1px #a626d3;
            }
        }

        input[type="submit"], button {
            width: 100%;
            border: none;
            border-radius: 5px;
            color: #fff;;
            padding: 15px;
            text-align: center;
            font-family: 'Karla', sans-serif;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            transition: .5s;
            -o-transition: .5s;
            -ms-transition: .5s;
            -moz-transition: .5s;
            -webkit-transition: .5s;


            &:hover {
                opacity: 0.75;
            }
        }

    }

    &__meme-holder {
        position: relative;
        margin: 30px 0 0;

        .meme-image {
            width: 100%;
            height: auto;
        }

        p {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            padding: 0 15px;
            font-size: 28px;
            line-height: 38px;
            color: #fff;
            text-transform: uppercase;
            text-shadow: 3px 3px 4px #000;
        }

        .top-text {
            top: 15px;
        }

        .bottom-text {
            bottom: 15px;
        }
    }


}