/* Base and default styles */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Karla:ital,wght@0,300,0,400;0,600;1,400;1,600&display=swap');

body {
	margin: 0;
	font-family: 'Karla', sans-serif;
	line-height: 1em;
}

.container {
	width: 550px;
	margin: 0 auto;
	padding: 0 15px;
}

.gradient {
	background: rgb(103,34,128);
	background: linear-gradient(90deg, rgba(103,34,128,1) 0%, rgba(166,38,211,1) 100%);
}